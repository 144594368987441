<ng-container *ngIf="displayBanner$ | async as displayBanner">
  <div
    *ngIf="displayBanner"
    id="dev_warning_header"
    class="tw-bg-orange-100 tw-border-orange-500 tw-text-orange-700 tw-border-t-4 tw-p-2 lg:tw-p-4 tw-text-xs sm:tw-text-base"
    [ngClass]
  >
    <span class="sm:tw-block tw-font-bold tw-font-dm-sans tw-text-base"
      >Warning: Down Time Scheduled
    </span>
    <div class="tw-flex tw-flex-row tw-justify-between">
      <p class="tw-inline sm:tw-block sm:tw-leading-7">
        <strong>APICURON</strong> will be temporarily unavailable during the
        entire day of 2024-10-11 UTC due to a scheduled Downtime.
      </p>
      <div>
        <button (click)="closeBanner()">
          <svg-icon
            src="assets/svg/close.svg"
            svgClass="tw-w-4 tw-h-4 tw-stroke-2"
          ></svg-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
