<div>
  <!-- banner container -->

  <div class="tw-fixed tw-bottom-0 tw-right-0 tw-left-0 tw-z-30 tw-flex tw-flex-col">

    <ng-container *ngFor="let banner of banners">
      <ng-container *ngComponentOutlet="banner"></ng-container>
    </ng-container>
  </div>


  <navbar></navbar>
  <div class="tw-min-h-screen tw-bg-gray-100">
    <router-outlet></router-outlet>
  </div>
  <app-foot></app-foot>
</div>